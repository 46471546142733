<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main deviceAdd">
            <el-form ref="deviceAddForm" :model="form" :rules="rules">
                <div class="a-fs-16 a-fw-700">设备信息</div>
                <el-form-item class="a-mt-32" label="" prop="deviceCategroy">
                    <le-select-remote-search class="editDevType" notnull label="设备种类" v-model="form.deviceCategroy"
                        :options="optionsDeviceCategroy" placeholder="请选择（可搜索）" />
                </el-form-item>
                <el-form-item class="a-mt-32" label="" prop="deviceType">
                    <le-select-remote-search class="editDevType" notnull label="设备类型" v-model="form.deviceType"
                        :options="optionsDeviceType" placeholder="请选择（可搜索）" />
                </el-form-item>
                <el-form-item label="" prop="slotNum" v-if="form.deviceCategroy == 1 || form.deviceCategroy == 3 || form.deviceCategroy == 4 || form.deviceCategroy == 5">
                    <le-input-num notnull class="editDevType" label="设备插槽数" v-model="form.slotNum" :maxlength="15"
                        placeholder="请输入"></le-input-num>
                </el-form-item>
                <el-form-item label="" prop="canFace" v-if="(form.deviceCategroy == 2)">
                    <le-select-local-search notnull class="editDevType" label="是否人脸" v-model="form.canFace" :options="yesOrNoDic" />
                </el-form-item>
                <el-form-item label="" prop="canRfid" v-if="(form.deviceCategroy == 2)">
                    <le-select-local-search notnull class="editDevType" label="是否RFID" v-model="form.canRfid" :options="yesOrNoDic" />
                </el-form-item>
                <el-form-item label="" prop="deviceCode">
                    <le-input notnull class="editDevType" label="设备编码" v-model="form.deviceCode" :maxlength="15"
                        placeholder="请输入"></le-input>
                </el-form-item>
                <el-form-item label="" prop="imei">
                    <le-input notnull class="editDevType" label="IMEI/厂商ID" v-model="form.imei"
                        placeholder="请输入"></le-input>
                </el-form-item>
                <el-form-item label="" prop="serialNumber">
                    <le-input label="序列号" class="editDevType" v-model="form.serialNumber"
                        placeholder="请输入"></le-input>
                </el-form-item>
                <el-form-item label="" prop="iccid">
                    <le-input label="ICCID" class="editDevType" v-model="form.iccid" :maxlength="20" placeholder="请输入">
                    </le-input>
                </el-form-item>
                <el-form-item label="" prop="canUse">
                    <le-switch class="editDevType" label="设备状态" v-model="form.canUse" actext="启用" inactext="禁用"
                        :acValue="1" :inacValue="0">
                    </le-switch>
                </el-form-item>
                <el-form-item label="" prop="mark">
                    <le-input label="备注" class="editDevType" v-model="form.mark" :maxlength="10" placeholder="请输入">
                    </le-input>
                </el-form-item>
                <div style="height:100px"></div>
                <div class="a-line-t-e0 footerBox">
                    <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">
                        &nbsp;&nbsp;&nbsp;确定&nbsp;&nbsp;&nbsp;</el-button>
                    <el-button class="a-ml-24 a-mt-15" @click="cancel" style="margin-left:24px">
                        &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>

export default {
    data() {
        return {
            optionsDeviceType: {
                url: this.$Config.apiUrl.getDeviceType,
                method: "post",
                params: {
                    deviceCategroy: ''
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "key",
            },
            optionsDeviceCategroy: {
                url: this.$Config.apiUrl.getDeviceCategroyList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "deviceCategroy",
                valueKey: "id",
                searchKey: "key",
            },
            form: {
                deviceCategroy: '',//设备种类
                deviceType: '',//设备类型
                slotNum: '',// 设备插槽数
                deviceCode: '',//设备编码
                imei: '',//IMEI/厂商ID
                serialNumber: '',//序列号
                iccid: '',
                canUse: 0,//设备状态
                mark: '',//备注
                canRfid: '0',
                canFace: '0'
            },
            rules: {
                deviceCategroy: [{ required: true, message: '请选择设备种类', trigger: 'change' }],
                deviceType: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
                slotNum: [{ required: true, message: '请输入设备插槽数', trigger: 'blur' }],
                deviceCode: [{ required: true, message: '请输入设备编码', trigger: 'blur' }],
                imei: [{ required: true, message: '请输入IMEI/厂商ID', trigger: 'blur' }],
                canRfid: [{ required: true, message: '请选择是否RFID', trigger: 'change' }],
                canFace: [{ required: true, message: '请选择是否人脸', trigger: 'change' }],
            },
            yesOrNoDic: []
        }
    },
    watch: {
        'form.deviceCategroy':{
            deep: true,
            handler (newVal, oldVal) {
                if(newVal != oldVal){
                    this.form.deviceType = ''
                    this.optionsDeviceType.params.deviceCategroy = newVal
                }
            }
        }
    },
    created () {
        this.$getDic('yesOrNo','select').then(res=>{ this.yesOrNoDic = res; })
    },
    methods: {
        submit() {
            this.$refs['deviceAddForm'].validate((valid) => {
                if (valid) {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.addDevice,
                        method: "post",
                        params: this.form
                    }).then(res => {
                        if (res.result.code == 0) {
                            this.$route.meta.hash = "del";
                            this.$message.success('添加成功')
                            this.$router.back()
                        } else {
                            this.$message.error(res.result.message)
                        }
                    }).catch(err => {
                        // this.$message.error(err)
                    })
                }
            })
        },
        cancel() {
            this.$router.back()
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 92px !important;
    justify-content: flex-start;
}

.deviceAdd {
    padding-bottom: 100px
}

.editDevType {
    padding: 0 0 6px 0 !important;
    width: 350px !important;
}

// .footerBox {
//     left: 255px;
//     right: 43px;
//     position: fixed;
//     bottom: 0;
//     background: #fff;
//     margin-bottom: 40px;
// }
</style>